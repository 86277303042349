import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/FirldTrips/2024/Class6/1.jpg';
// import p2 from 'assests/Photos/FirldTrips/2024/Class6/2.jpg';
// import p3 from 'assests/Photos/FirldTrips/2024/Class6/3.jpg';
// import p4 from 'assests/Photos/FirldTrips/2024/Class6/4.jpg';
// import p5 from 'assests/Photos/FirldTrips/2024/Class6/5.jpg';
// import p6 from 'assests/Photos/FirldTrips/2024/Class6/6.jpg';
// import p7 from 'assests/Photos/FirldTrips/2024/Class6/7.jpg';
// import p8 from 'assests/Photos/FirldTrips/2024/Class6/8.jpg';



import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2024 from '../CurricularClass52023/components/Sidebar2024';
import Archive_2022 from '../CurricularClass52023/Archive_2022';


const Class12Fieldtrip2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class12Fieldtrip/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class12Fieldtrip/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class12Fieldtrip/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class12Fieldtrip/4.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1.7,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1.7,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                Field trip to Area 83 in Bannergatta South
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 12                           DATE OF EVENT: 01 OCTOBER 2024

                                </Typography>
                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                The students of Grade 12 from National Public School Yeshwanthpur embarked on a memorable field trip to Area 83 in
                                 Bannergatta South on Monday, October 1, 2024. Nestled beside a serene lake and surrounded by lush greenery,
                                 the resort provided a refreshing escape for tired eyes, stressful minds and overburdened hearts. The day was filled
                                 with exciting experiences that not only brought them closer together but also offered a delightful break from their usual routine.
                                    <br />
                                    Their adventure began bright and early at 7 AM when they gathered at school and boarded two buses headed for the resort. 
                                    The journey was lively, filled with music, singing and spontaneous dance sessions that created a joyful and vibrant atmosphere. 
                                    Upon arrival, they were welcomed to the resort's recreational area, served with
                                     sumptuous breakfast, given instructions and a brief overview of what the resort had to offer by the support staff. 
                                    <br />
                                    Later, they were divided into four groups, each having the chance to participate in thrilling activities such as the Giant Swing, 
                                    Human Foosball, Bubble Soccer, Archery and the enticing pool. These challenges not only brought excitement but also pushed them 
                                    physically and mentally. The day served as a refreshing getaway from their academic routine,
                                     allowing them to relax, unwind and enjoy the company of their peers.
                                    <br />
                                    As the action-packed day came to a close, the students gathered for a group photo, capturing the essence of their 
                                    shared experiences. With sumptuous food, well-planned activities, stunning surroundings and the camaraderie of like-minded friends,
                                     the day ended with thrilling adventures, meaningful connections and lasting memories.
                                    <br />
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                "The best thing about memories is making them"
                                <br />
                            </Typography>


                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive_2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Class12Fieldtrip2024;